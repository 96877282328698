<template>
  <base-layout>
    <UserAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :user-id="ae.userId"
      :user-data="ae.userData"
    />
    <content-section :spacing="false">
      <div class="content-spacing px-4">
        <title-plus title="Operator Users" @plus="onOpenES" class="mb-10" />
        <div class="flex mt-4 mb-10 flex-col-8 md:items-center md:flex-row">
          <card-tax-stat
            title="Total Operators"
            :value="indexMetaData.summary.total"
            variant="yellow"
            class="mr-3"
          />
          <card-tax-stat
            title="Ongoing Tasks"
            :value="indexMetaData.summary.total_ongoing_task"
            variant="green"
            class="mr-3"
          />
          <card-tax-stat
            title="Completed Tasks"
            :value="indexMetaData.summary.total_completed_task"
            variant="light-blue"
            class=""
          />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="vld-parent px-4">
        <loading :active.sync="isLoading" :is-full-page="false" />
        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>
              <router-link
                class="font-bold text-blue-600 capitalize"
                :to="{
                  name: 'ViewOrganizationUserProfile',
                  params: { id: item.id },
                }"
                target="_blank"
              >
                #{{ item.id | onlyLastFive }}
              </router-link>
            </fsTableRowItem>
            <fsTableRowItem>
              <router-link
                class="font-bold text-blue-600 capitalize"
                :to="{
                  name: 'ViewOrganizationUserProfile',
                  params: { id: item.id },
                }"
                target="_blank"
              >
                {{ item.full_name }}
              </router-link>
            </fsTableRowItem>
            <fsTableRowItem>{{ item.phone_number }}</fsTableRowItem>
            <fsTableRowItem>{{ item.total_points }}</fsTableRowItem>
            <fsTableRowItem
              ><div v-if="item.last_login">
                {{ item.last_login | friendlyDateTime }}
              </div>
              <div v-else>--</div></fsTableRowItem
            >
            <fsTableRowItem
              ><x-status
                :variant="getUserStatusVariant(item.user_status)"
                :text="getUserStatus(item.user_status)"
                profile="user"
              />
            </fsTableRowItem>
            <fsTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="onOpenES(item.id)" />
                <UserIndexActions
                  :primary-key="item.id"
                  :data="item"
                  @accountStatusUpdated="getIndexData(getQueryString)"
                />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>
        <!-- fs-table-footer -->
        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
        <!-- fs-table-footer -->
      </fsTableContainer>
      <!-- end: table-container -->
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import OtoSearch from '@/components/ui/OtoSearch'

// Mixins
import isLoadingMixin from '@/mixins/isLoadingMixin'
// GetIndexData & QueryString generator
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
// FsTable UI
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import UserAddEdit from './UserAddEdit'
// SubscriptionConfig
import { OperatorUserConfig } from '@/config/OperatorUserConfig'

// import { EventBus } from "@/utils/EventBus";
import CardTaxStat from '@/components/cards/TaxStat'
import XStatus from '@/components/badge/XStatus'
// import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'
import UserIndexActions from './UserIndexActions.vue'
export default {
  name: 'Franchise Users',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    ExportAsDropdown,
    OtoEditIcon,
    OtoSearch,
    CardTaxStat,
    XStatus,
    UserAddEdit,
    UserIndexActions,
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total: 0,
          total_ongoing_task: 0,
          total_completed_task: 0,
        },
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: OperatorUserConfig.api.index,
      statusChoice: {
        A: 'Active',
        D: 'Inactive',
        H: 'On Hold',
      },
      tableHeaders: [
        {
          name: 'ID',
          width: '10%',
          field: 'id',
          sort: null,
          order: '',
        },
        {
          name: 'Full Name',
          width: '15%',
          field: 'full_name',
          sort: true,
          order: '',
        },
        {
          name: 'Phone Number',
          width: '15%',
          field: 'organization_name',
          sort: true,
          order: '',
        },
        {
          name: 'Total Points',
          width: '15%',
          field: 'email',
          sort: true,
          order: '',
        },
        {
          name: 'Last Login',
          width: '15%',
          field: 'role',
          sort: null,
          order: '',
        },
        {
          name: 'Account Status',
          width: '15%',
          field: 'last_login',
          sort: null,
          order: '',
        },
        {
          name: 'Actions',
          width: '10%',
          field: null,
          sort: null,
          order: '',
        },
      ],
      // AddEdit (ae)
      ae: {
        // EdgeStack component
        esId: 'user-add-edit',
        // XStepper component
        stepperMode: 'free',
        stepperStep: 1,
        // Data
        data: {},
        userId: null,
        userData: null,
      },
    }
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    window.addEventListener(OperatorUserConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    getUserStatus: function(status) {
      return status in this.statusChoice ? this.statusChoice[status] : '-'
    },
    getUserStatusVariant(status) {
      let collection = {
        A: 'green',
        D: 'red',
        H: 'orange',
      }
      return status in collection ? collection[status] : 'purple'
    },
    // EdgeStack component
    async onOpenES(userId = null) {
      // if userId provided, open it in free (aka edit) mode, inject user data as well
      // otherwise, open it in strict (aka add mode). In both case, inject required data,
      // but user data is required in edit (free) mode.
      if (userId) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Processing..`,
            text: 'The action is being executed...',
          },
          600
        )
        // fetch user data
        await this.$http
          .get(this.$config.orgUser.api.single(userId))
          .then((res) => {
            console.log('res-data', res.data)
            this.ae.stepperMode = 'free'
            this.ae.userId = res.data.id
            this.ae.userData = res.data
            this.$edgeStack.open(this.ae.esId)
          })
          .catch((err) => {
            console.warn({ err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Action failed to execute!`,
                text: 'Failed to retrieve the user. Please try again.',
              },
              5000
            )
          })
      } else {
        // this.$xStepper.navigate(this.ae.esId).to(4)
        this.ae.stepperMode = 'strict'
        this.ae.userId = null
        this.ae.userData = null
        this.$edgeStack.open(this.ae.esId)
      }
    },
    view: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.viewingData, item);
      // this.$modal.show(SubscriptionConfig.events.viewingData);
    },
    getActionsEndpoint(id) {
      return OperatorUserConfig.api.update(id)
    },
  },
}
</script>

<style></style>

export const OperatorUserConfig = {
  api: {
    // get
    index: '/dashboard/operators/',
    // get
    single: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/`
    },
    // post
    create: '/dashboard/operators/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/operators/${id}/`
    },
    // put
    updateStatus(id = 'uuid') {
      return `/org-users/${id}/update-status/`
    },
  },
  events: {
    name: 'operator',
    // refresh-index-data
    refresh: `rid-operator`,
    // slideover-right
    sorId: 'fleet',
    sorOpen: 'sor-open-operator',
    sorClose: 'sor-close-operator',
    sorToggle: 'sor-toggle-operator',
    // editing-data
    editingData: 'edit-operator-data',
    // viewing-data
    viewingData: 'view-operator-data',
  },
}
